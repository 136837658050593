<template>
	<AdministrationUsersLayout user-type="Admin Portal">
		<template #content>
			<FilterBar
				:search-query.sync="adminPortalUserSearchQuery"
				search-query-label="Name"
				:selected-brand-id.sync="selectedAdminPortalBrandId"
				@persistSearchQuery="changeAdminPortalUserSearchQuery"
				@persistSelectedBrandId="changeAdminPortalBrandFilter"
				@clearFilters="clearFilters"
			>
				<template #after-filters>
					<Dropdown
						v-model="selectedAdminPortalUserStatus"
						label="Status"
						:items="statusDropdownItems"
						custom-sort
						style="flex: none;"
						@input="changeAdminPortalUserStatusFilter($event)"
					/>
				</template>
				<template #action>
					<PrimaryActionButton
						v-if="userCanCreateUpdate"
						@click="onCreateClick"
					>
						<v-icon
							left
							dark
						>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					Admin Portal Users
				</template>
				<template #body>
					<DataTable
						:items="filteredUsers"
						:headers="tableHeaders"
						sort-by="userId"
						sort-desc
						@click:row="onEditClick"
					>
						<template #item.isActive="{ item }">
							{{ item.isActive ? 'Active' : 'Inactive' }}
						</template>
						<template #item.action="{ item }">
							<IconButton
								v-if="userCanCreateUpdate"
								tooltip-text="Edit Admin Portal User"
								@click="onEditClick(item)"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-else
								tooltip-text="View Admin Portal User"
								@click="onEditClick(item)"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="userCanCreateUpdate"
								tooltip-text="Delete Admin Portal User"
								@click.stop.prevent="onRemoveClick(item)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<ConfirmDeleteModal
				v-if="userToBeRemoved"
				:entity-name="userToBeRemoved.name"
				entity-type="Admin Portal User"
				@close="userToBeRemoved = null"
				@delete="deleteUser"
			/>
		</template>
	</AdministrationUsersLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import AdministrationUsersLayout from '../administration-users-layout.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import {
	adminPortalUsers,
	adminPortalProductRoles,
	loadAdminPortalUsers,
	legacyPermissionGroups,
	changeAdminPortalUserSearchQuery,
	changeAdminPortalUserStatusFilter,
	adminPortalUserSearchQuery,
	selectedAdminPortalUserStatus
} from '../../../../../../shared/state/admin-portal-users-and-lookup-data.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import { adminPortalBrandFieldLabel } from '../../../../../../shared/state/config-keys.js'
import { ADMIN_PORTAL_MANAGE_USER } from '../../../../router/route-names.js'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { deleteAdminPortalUser } from '../../../../../../shared/utils/api/admin-portal-users.js'
import { USERS_MODULE_FULL_PERMISSIONS, ADMIN_PORTAL_USERS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		ConfirmDeleteModal,
		FilterBar,
		AdministrationUsersLayout,
		Dropdown,
		PrimaryActionButton,
		IconButton,
		DataTable,
		SectionCard
	},
	setup () {
		return {
			showSnackbar,
			adminPortalUsers,
			adminPortalProductRoles,
			adminPortalBrandFieldLabel,
			changeAdminPortalUserSearchQuery,
			changeAdminPortalUserStatusFilter,
			adminPortalUserSearchQuery,
			selectedAdminPortalUserStatus,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			userToBeRemoved: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		tableHeaders () {
			return [
				{
					value: 'userId',
					text: 'ID',
					width: '5%'
				},
				{
					value: 'brandNames',
					text: `${adminPortalBrandFieldLabel.value}(s)`,
					width: '25%'
				},
				{
					value: 'name',
					text: 'Name',
					width: '20%'
				},
				{
					value: 'emailAddress',
					text: 'Email Address',
					width: '20%'
				},
				{
					value: 'isActive',
					text: 'Status',
					width: '10%'
				},
				{
					value: 'createdDate',
					text: 'Created Date',
					width: '10%'
				},
				{
					value: 'action',
					text: 'Action',
					width: '10%',
					sortable: false
				}
			]
		},
		filteredUsers () {
			return this.adminPortalUsers.filter(({ name, isActive, brandIds }) => {
				let check = true
				if (adminPortalUserSearchQuery.value) check = name.toLowerCase().includes(adminPortalUserSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandIds.includes(selectedAdminPortalBrandId.value)
				if (selectedAdminPortalUserStatus.value !== null) check = check && Boolean(isActive) === selectedAdminPortalUserStatus.value
				return check
			}).map(user => ({
				...user,
				legacyPermissionGroups: user.legacyPermissionGroupIds?.map(userLegacyPermissionGroup => {
					const matchingPermissionGroup = legacyPermissionGroups.value.find(({ permissionGroupId }) => permissionGroupId === userLegacyPermissionGroup)
					return matchingPermissionGroup ? matchingPermissionGroup.permissionGroupName : ''
				})
			}))
		},
		statusDropdownItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Active',
					value: true
				},
				{
					text: 'Inactive',
					value: false
				}
			]
		},
		userCanCreateUpdate () {
			if (this.productAreaPermission(USERS_MODULE_FULL_PERMISSIONS) === true) {
				return true
			}
			return this.productAreaPermission(ADMIN_PORTAL_USERS_MODULE_FULL_PERMISSIONS)
		}
	},
	async created () {
		await loadAdminPortalUsers()
	},
	methods: {
		onCreateClick () {
			this.$router.push({
				name: ADMIN_PORTAL_MANAGE_USER
			})
		},
		onEditClick (userToEdit) {
			this.$router.push({
				name: ADMIN_PORTAL_MANAGE_USER,
				params: {
					userToEdit
				}
			})
		},
		async onRemoveClick (userToBeRemoved) {
			this.userToBeRemoved = userToBeRemoved
		},
		async deleteUser () {
			await deleteAdminPortalUser(this.userToBeRemoved.userId)
			await loadAdminPortalUsers()
			this.userToBeRemoved = null
			this.showSnackbar('This user has been deleted')
		},
		clearFilters () {
			changeAdminPortalUserStatusFilter(null)
		}
	}
}
</script>
