<template>
	<ViewLayout>
		<template #header-title>
			{{ userType }} Users
		</template>
		<template #header-caption>
			Add, edit and manage {{ userType.toLowerCase() }} users
		</template>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout },
	props: {
		userType: {
			type: String,
			default: null
		}
	}
}
</script>
